import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import './index.scss';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Box, CircularProgress, FormControl } from '@mui/material';
import helper from '../../helpers/helper';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

function PosterForm({ onChange, data, nextStep, previousStep }) {
    const [loading, setLoading] = useState(false);

    const handleGenerate = async () => {
        setLoading(true);
        let result = await axios.post(helper.getDomain() + "/api/events/posterImage", { ...data });
        let resultData = result.data?.data?.data;
        setLoading(false);
        onChange('posterImages', resultData);
console.log("@@resultData",resultData);

        handleProgram();
    }


    const handleProgram = async () => {
        setLoading(true);
        let result = await axios.post(helper.getDomain() + "/api/events/request", { ...data, type: 'posterProgram' });
        let resultData = result.data?.data?.data;
        setLoading(false);
        onChange('posterProgram', resultData);
    }

    return (
        <div className="poster-form">
            <div className="form__container">
                <div className="form__container__left">
                    <h2>Flyer</h2>
                    <h3>Genereer nu je flyer</h3>
                    <Box style={{ marginTop: 2, marginBottom: '2em' }}>
                        <Button disableElevation variant="contained" onClick={() => handleGenerate()}>Genereer poster</Button>
                    </Box>
                    {loading && (<CircularProgress style={{ marginTop: 2 }} />)}

                </div>

                <div className="form__container__right">
                    {data.posterImages && (
                        <div className="poster-form__preview">
                            <div class="poster-bar"></div>
                            <div className="poster-container">
                                <div className="poster-title">{data.name}</div>
                                <div className="poster-program">
                                    <ReactMarkdown remarkPlugins={[remarkGfm]}>{data.posterProgram}</ReactMarkdown>

                                </div>
                            </div>
                            <div className="poster-date">{data.date}</div>
                            <div className="poster-image1" style={{ backgroundImage: 'url(' + data.posterImages[0] + ')' }}></div>
                            <div className="poster-image3" style={{ backgroundImage: 'url(' + data.posterImages[1] + ')' }}></div>
                        </div>
                    )}
                </div>
            </div>

            <div className="form__footer">
                <div className="form__footer__left">
                    <Button disableElevation variant="outlined" onClick={previousStep}>Vorige</Button>
                </div>
                <div className="form__footer__center"></div>
                <div className="form__footer__right">
                    <Button disableElevation variant="contained" onClick={nextStep}>Volgende</Button>
                </div>
            </div>
        </div>
    )
}
export default PosterForm;