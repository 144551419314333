import { useState } from 'react';
import axios from 'axios';
import './index.scss';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button, CircularProgress } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import helper from '../../helpers/helper';
import Alert from '@mui/material/Alert';
import validator from 'validator'

function NameForm({ onChange, data, nextStep, previousStep }) {
    const [names, setNames] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const generateName = async (retry = false) => {
        setLoading(true);

        let result = await axios.post(helper.getDomain() + "/api/events/request", { ...data, type: 'name' });
        let resultData = result.data?.data?.data;
        setLoading(false);
        let names = extractBetweenBrackets(resultData);
        console.log("@@@names",names)
        try {
            let namesResult = JSON.parse(names);
            setNames(namesResult);
        }
        catch (e) {
            console.log("JSON error", e, names);
            if (!retry) {
                generateName(true);
            }
        }
    }

    const handleClickRow = (name) => {
        onChange('name', name);
        setNames([])
    }

    function extractBetweenBrackets(inputString) {
        const startIndex = inputString.indexOf('[');
        const lastIndex = inputString.lastIndexOf(']');

        if (startIndex !== -1 && lastIndex !== -1 && lastIndex > startIndex) {
            return inputString.substring(startIndex, lastIndex + 1);
        } else {
            return '';
        }
    }

    const nextClick = () => {
        if (!data.userName) {
            setError("Je dient je naam in te vullen");
            return;
        }
        if (!data.email || !validator.isEmail(data.email)) {
            setError("Je dient je emailadres in te vullen");
            return;
        }
        if (!data.name) {
            setError("Je dient je eventnaam in te vullen");
            return;
        }

        nextStep();
    }

    return (
        <div className="name-form">
            <div className="name-form__container">
                <h2>Start nu met je evenement</h2>
                <h3>Wij helpen je bij de organisatie van je ideale event.</h3>
                <div>We gaan je met behulp van AI helpen om het ideale evenement samen te stellen. In verschillende stappen helpen we je bij alle mogelijkheden voor het evenement. </div>
                {error && (
                    <Alert severity="error" sx={{ marginTop: 2 }}>{error}</Alert>
                )}
                <div className="field__item">
                    <FormControl sx={{ marginTop: 4 }} fullWidth >
                        <TextField
                            id="outlined-multiline-flexible"
                            label="Je naam"
                            multiline
                            fullWidth
                            minRows={1}
                            maxRows={4}
                            value={data.userName}
                            onChange={(event) => onChange('userName', event.target.value)}
                        />
                    </FormControl>
                </div>
                <div className="field__item">
                    <FormControl sx={{ marginTop: 2 }} fullWidth >
                        <TextField
                            id="outlined-multiline-flexible"
                            label="Je emailadres"
                            multiline
                            fullWidth
                            minRows={1}
                            maxRows={4}
                            value={data.email}
                            onChange={(event) => onChange('email', event.target.value)}
                        />
                    </FormControl>
                </div>

                <div className="field__item">
                    <FormControl sx={{ marginTop: 2 }} fullWidth >
                        <TextField
                            id="outlined-multiline-flexible"
                            label="Korte beschrijving van het evenement"
                            multiline
                            fullWidth
                            minRows={1}
                            maxRows={4}
                            value={data.shortDescription}
                            onChange={(event) => onChange('shortDescription', event.target.value)}
                        />
                    </FormControl>
                </div>
                <div className="field__item">
                    <FormControl sx={{ minWidth: 220, marginTop: 3 }} >
                        <InputLabel id="field-language-label">Taal</InputLabel>
                        <Select
                            labelId="field-language-label"
                            id="field-language"
                            value={data.language}
                            label="Taal"
                            onChange={(event) => onChange('language', event.target.value)}
                        >
                            <MenuItem value={'NL'}>Nederlands</MenuItem>
                            <MenuItem value={'EN'}>Engels</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className="field__item">
                    <FormControl fullWidth sx={{ marginTop: 3, marginBottom: 1 }} >
                        <TextField
                            label="Naam van het evenement"
                            fullWidth
                            value={data.name}
                            onChange={(event) => onChange('name', event.target.value)}
                        />
                    </FormControl>

                    <div className="field__item__generate-name">
                        <Button variant="text" onClick={generateName}>Suggereer een naam</Button>
                    </div>

                    {loading && (<CircularProgress style={{ marginTop: '1em' }} />)}

                    {names && names.length > 0 && (
                        <div className="name-form__name-suggestions">
                            <Table>

                                <TableBody>
                                    {names.map((row, index) => (
                                        <TableRow
                                            key={row.name}
                                        >
                                            <TableCell component="th" scope="row" onClick={() => handleClickRow(row.name)}>
                                                {index + 1}   {row.name}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    )}
                </div>

            </div>
            <div className="name-form__footer">
                <div className="name-form__footer__left">

                </div>
                <div className="name-form__footer__center"></div>
                <div className="name-form__footer__right">
                    <Button variant="contained" onClick={nextClick}>Volgende</Button>
                </div>
            </div>
        </div >
    )
}
export default NameForm;