import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import './index.scss';
import Button from '@mui/material/Button';
import helper from '../../helpers/helper';

function Information({ onChange, data, nextStep, previousStep }) {


    return (
        <div className="email-form">
            <div className="form__container">
                <div className="form__container__left">
                    <h1>Bedankt</h1>
                    <h3>Tof dat je zelfs tot de laatste stap gekomen bent!</h3>
                    <p>
                        We hopen dat we je hebben kunnen helpen, inspireren of - wellicht - gerustgesteld hebben. Wil je de volgende stap maken op digitaal vlak op je evenement? Neem dan contact met ons op voor;
                    </p>
                    <ul>
                        <li><a href="https://www.walldo.nl" target="_blank" rel="noreferrer">Walldo</a> - je registratiewebsite & -partner</li>
                        <li><a href="https://www.presenterswall.nl" target="_blank" rel="noreferrer">PresentersWall</a> - jouw interactieve mobiele event hub</li>
                        <li><a href="https://www.evenue.nl" target="_blank" rel="noreferrer">eVenue</a> - jouw online event platform</li>
                    </ul>

                </div>
                <div className="form__container__right">
                    <video src="https://videos.ctfassets.net/uisfvs49tufg/4lWkYG5SwIThknU04GeMtn/88e0d2e639b32ec7a05122c4081b244b/walldo.mp4" width="100%" controls autoPlay muted></video>

                </div>
            </div>
            <div className="form__footer">
                <div className="form__footer__left">
                    <Button disableElevation variant="outlined" onClick={previousStep}>Vorige</Button>
                </div>

            </div>
        </div>
    )
}
export default Information;