import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import './index.scss';
import Button from '@mui/material/Button';
import { Box, CircularProgress, FormControl } from '@mui/material';
import helper from '../../helpers/helper';
import Markdown from 'react-markdown'

function DetailsResult({ onChange, data, nextStep, previousStep }) {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!data.longDescription) {
            handleGenerate();
        }
    }, []);

    const handleGenerate = async () => {
        setLoading(true);
        let result = await axios.post(helper.getDomain() + "/api/events/request", { ...data, type: 'description' });
        let resultData = result.data?.data?.data;
        setLoading(false);
        onChange('longDescription', resultData);
    }

    const renderWithLineBreaks = (text) => {
        const lines = text.split('\n');
        return lines.map((line, index) => (
            <React.Fragment key={index}>
                {index === 0 ? <b>{line}</b> : line}
                {index !== lines.length - 1 && <br />} {/* Add <br> except after the last line */}
            </React.Fragment>
        ));
    };

    return (
        <div className="description-result">
            <div className="form__container">

                <h2>Je evenement</h2>
                <div className="description-result__preview">
                    {data.longDescription && (<Markdown>{data.longDescription}</Markdown>)}
                </div>

                <div>
                    {loading && <CircularProgress />}
                    {!loading && <Button disableElevation variant="contained" onClick={() => handleGenerate()}>Genereer beschrijving</Button>}
                </div>
            </div>

            <div className="form__footer">
                <div className="form__footer__left">
                    <Button disableElevation variant="outlined" onClick={previousStep}>Vorige</Button>
                </div>
                <div className="news-article-form__footer__center"></div>
                <div className="news-article-form__footer__right">
                    <Button disableElevation variant="contained" onClick={nextStep}>Volgende</Button>
                </div>
            </div>
        </div>
    )
}
export default DetailsResult;