import { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from '../SideBar';
import NameForm from '../NameForm';
import DetailsForm from '../DetailsForm';
import DetailsResult from '../DetailsResult';
import EmailForm from '../EmailForm';
import NewsArticleForm from '../NewsArticleForm';
import ScriptForm from '../ScriptForm';
import SocialForm from '../SocialForm';
import LookbackForm from '../LookBackForm';
import PosterForm from '../PosterForm';
import SlidesForm from '../SlidesForm';
import cloneDeep from 'lodash/cloneDeep';
import helper from '../../helpers/helper';
import './index.scss';
import Information from '../Information';
let sourceData = {};

function BaseView() {

    const [step, setStep] = useState(1);
    const [data, setData] = useState({});

    useEffect(() => {
        if (!data.language) {
            const initialData = localStorage.getItem('data') ? JSON.parse(localStorage.getItem('data')) : { name: '', language: 'NL', newsArticlePrompt: '', emailPrompt: '', scriptPrompt: '' };
            sourceData = initialData;
            setData(initialData);
        }
    }, []);

    const changeStep = (step) => {
        // Check if validated
        if (data['stepDone' + step]) {
            setStep(step);
        }
    }

    const nextStep = async () => {
        // Save data
        let result = await axios.post(helper.getDomain() + "/api/events/save", { token: data.token, data });

        // Set new state
        let token = result.data?.data?.token;
        if (token) {
            handleChange('token', token);
            handleChange('stepDone' + step, true);
        }
        else {
            handleChange('stepDone' + step, true);
        }
        setStep(step + 1);
    }

    const previousStep = () => {
        setStep(step - 1);
    }

    const handleChange = (field, value) => {
        let newData = cloneDeep(sourceData);
        console.log("Start", { ...newData });
        if (Array.isArray(field)) {
            field.forEach((item) => {
                for (const [key, value] of Object.entries(item)) {
                    newData[key] = cloneDeep(value);
                }
            });
            localStorage.setItem('data', JSON.stringify(newData));
            sourceData = newData;
            setData(newData);
        }
        else {
            newData[field] = cloneDeep(value);
            sourceData = newData;
            setData(newData);
            console.log("End", { ...newData });
            localStorage.setItem('data', JSON.stringify(newData));
        }
    }

    return (
        <div className="App">

            <div className="app-container">

                <div className="app-area__sidebar">
                    <div className="logo">

                    </div>

                    <Sidebar step={step} onChange={changeStep} />

                    <div className="livewall">
                        <a href="https://www.livewall.nl" target="_blank" rel="noreferrer">
                        </a>
                    </div>
                </div>

                <div className="app-area__main">
                    {step === 1 && (<NameForm data={data} onChange={handleChange} nextStep={nextStep} previousStep={previousStep} />)}
                    {step === 2 && (<DetailsForm data={data} onChange={handleChange} nextStep={nextStep} previousStep={previousStep} />)}
                    {step === 3 && (<DetailsResult data={data} onChange={handleChange} nextStep={nextStep} previousStep={previousStep} />)}
                    {step === 4 && (<EmailForm data={data} onChange={handleChange} nextStep={nextStep} previousStep={previousStep} />)}
                    {step === 5 && (<ScriptForm data={data} onChange={handleChange} nextStep={nextStep} previousStep={previousStep} />)}
                    {step === 6 && (<NewsArticleForm data={data} onChange={handleChange} nextStep={nextStep} previousStep={previousStep} />)}
                    {step === 7 && (<SocialForm data={data} onChange={handleChange} nextStep={nextStep} previousStep={previousStep} />)}
                    {step === 8 && (<PosterForm data={data} onChange={handleChange} nextStep={nextStep} previousStep={previousStep} />)}
                    {step === 9 && (<SlidesForm data={data} onChange={handleChange} nextStep={nextStep} previousStep={previousStep} />)}
                    {step === 10 && (<LookbackForm data={data} onChange={handleChange} nextStep={nextStep} previousStep={previousStep} />)}
                    {step === 11 && (<Information data={data} onChange={handleChange} nextStep={nextStep} previousStep={previousStep} />)}
                </div>
            </div>

        </div >
    );
}

export default BaseView;
