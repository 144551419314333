import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import './index.scss';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Box, CircularProgress, FormControl } from '@mui/material';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import helper from '../../helpers/helper';

function SlidesForm({ onChange, data, nextStep, previousStep }) {
    const [loading, setLoading] = useState(false);

    const handleGenerateTitle = async () => {
        // Presentation title
        let result = await axios.post(helper.getDomain() + "/api/events/request", { ...data, type: 'slidesTitle' });
        let resultData = result.data?.data?.data;
        onChange('slidesTitle', resultData);
    }

    const handleGenerate = async () => {
        setLoading(true);

        handleGenerateTitle();

        // Slide titles
        let result = await axios.post(helper.getDomain() + "/api/events/request", { ...data, type: 'slides' });
        let resultData = result.data?.data?.data;

        // Get slides
        let slideTitles = [];
        let slides = resultData.split("\n");
        for (let slide of slides) {
            if (slide.startsWith("Slide ")) {
                slideTitles.push(slide);
            }
        }

        const promises = slideTitles.map(async title => {
            try {
                const response = await axios.post(helper.getDomain() + "/api/events/request", { title, name: data.name, slidesPrompt: data.slidesPrompt, language: data.language, type: 'slideItem' });
                return response.data;
            } catch (error) {
                console.error('Error fetching data:', error);
                throw error;
            }
        });


        // Request all slides
        let slidesContent = [];
        try {
            const results = await Promise.all(promises);
            results.forEach((result, i) => {
                let title = slideTitles[i];
                if (title.substring(0, 6) === "Slide " && title.indexOf(':') > -1) {
                    title = title.substring(title.indexOf(':') + 1);
                }

                slidesContent.push({ title: title, content: result.data.data });
            });
        } catch (error) {
            console.error('Error fetching data:', error);
        }


        setLoading(false);
        onChange('slidesContent', slidesContent);
    }


    return (
        <div className="slides-form">
            <div className="form__container">
                <div className="form__container__left">
                    <h2>Presentatie Slides</h2>
                    <h3>Genereer je slides</h3>
                    <p>
                        Wil je direct starten met een sterke presentatie? Maak hier je presentatieslides. Beschrijf waar de presentatie over moet gaan en maak direct een goede start.
                    </p>
                    <div className="field__item">
                        <FormControl fullWidth sx={{ marginTop: 2, marginBottom: 2 }} >

                            <TextField
                                label="Inhoud van de presentatie"
                                multiline
                                fullWidth
                                minRows={2}
                                maxRows={5}
                                value={data.slidesPrompt}
                                onChange={(event) => onChange('slidesPrompt', event.target.value)}
                            />
                        </FormControl>
                    </div>
                    {data.slidesPrompt && !loading && (
                        <Box style={{ marginTop: 2, marginBottom: '2em' }}>
                            <Button disableElevation variant="contained" onClick={() => handleGenerate()}>Genereer slides</Button>
                        </Box>)}
                    {loading && (<CircularProgress style={{ marginTop: 2 }} />)}

                </div>

                {data.slidesContent && (
                    <div className="form__container__right">
                        <div className="slides-form__preview">

                            <div className="slide slide--cover">
                                <div className="slide__container">
                                    <div>
                                        <div className="slide__event-title">{data.name}</div>
                                        <div className="slide__deck-title">{data.slidesTitle}</div>
                                    </div>
                                </div>
                            </div>
                            {data.slidesContent && data.slidesContent.map((slide, i) => (
                                <div className={"slide slide--" + (i % 4)}>
                                    <div className="slide__container">
                                        <div class="slide__main">
                                            <div className="slide__title">
                                                {slide.title}
                                            </div>
                                            <div className="slide__content">
                                                <ReactMarkdown remarkPlugins={[remarkGfm]}>{slide.content}</ReactMarkdown>
                                            </div>
                                        </div>
                                        <div class="slide__image" style={{ backgroundImage: 'url(event' + (i + 1) + '.jpg)' }}>

                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>

            <div className="form__footer">
                <div className="form__footer__left">
                    <Button disableElevation variant="outlined" onClick={previousStep}>Vorige</Button>
                </div>
                <div className="form__footer__center"></div>
                <div className="form__footer__right">
                    <Button disableElevation variant="contained" onClick={nextStep}>Volgende</Button>
                </div>
            </div>
        </div>
    )
}
export default SlidesForm;