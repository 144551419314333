import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import './index.scss';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { Box, CircularProgress, FormControl } from '@mui/material';
import gmail from './gmail.png';
import helper from '../../helpers/helper';

function EmailForm({ onChange, data, nextStep, previousStep }) {
    const [loading, setLoading] = useState(false);
    const [variant, setVariant] = useState(1);

    const handleGenerate = async () => {
        setLoading(true);

        const requests = [
            axios.post(helper.getDomain() + "/api/events/request", { ...data, type: 'email1' }),
            axios.post(helper.getDomain() + "/api/events/request", { ...data, type: 'email1Subject' }),
            axios.post(helper.getDomain() + "/api/events/request", { ...data, type: 'email2' }),
            axios.post(helper.getDomain() + "/api/events/request", { ...data, type: 'email2Subject' })
        ];
        const results = await Promise.all(requests);
        let changes = [];
        results.forEach((result, index) => {
            const resultData = result.data?.data?.data;
            if (index === 0) {
                changes.push({ email1: resultData });
            }
            if (index === 1) {
                changes.push({ emailSubject1: resultData });
            }
            if (index === 2) {
                changes.push({ email2: resultData });
            }
            if (index === 3) {
                changes.push({ emailSubject2: resultData });
            }
        });

        onChange(changes);

        setLoading(false);
    }

    const handleVariant = (variant) => {
        setVariant(variant);
    }

    const renderWithLineBreaks = (text) => {
        const lines = text.split('\n');
        return lines.map((line, index) => (
            <React.Fragment key={index}>
                {line}
                {index !== lines.length - 1 && <br />} {/* Add <br> except after the last line */}
            </React.Fragment>
        ));
    };

    return (
        <div className="email-form">
            <div className="form__container">
                <div className="form__container__left">
                    <h1>Email invite</h1>
                    <h3>Genereer nu je uitnodiging email</h3>
                    <p>
                        Je kan hier een email genereren die je kan gebruiken om je deelnemers uit te nodigen.
                        Druk op genereer om direct een email te maken of geef aanvullende instructies over de stijl of inhoud.
                    </p>
                    <div className="field__item">
                        <FormControl fullWidth sx={{ marginTop: 2, marginBottom: 2 }} >

                            <TextField
                                label="Aanvullende sturing aan je email"
                                multiline
                                fullWidth
                                minRows={2}
                                maxRows={5}
                                value={data.emailPrompt}
                                onChange={(event) => onChange('emailPrompt', event.target.value)}
                            />
                        </FormControl>
                    </div>
                    {!loading && (
                        <Box style={{ marginTop: 2, marginBottom: '2em' }}>
                            <Button disableElevation variant="contained" onClick={() => handleGenerate()}>Genereer email</Button>
                        </Box>
                    )}
                    {loading && (<CircularProgress style={{ marginTop: 2 }} />)}

                </div>

                {data['emailSubject' + variant] && (
                    <div className="form__container__right">
                        <div className="email-form__preview">
                            <ButtonGroup disableElevation variant="contained">
                                <Button disableElevation variant={variant === 1 ? 'contained' : 'outlined'} onClick={() => handleVariant(1)}>Variant 1</Button>
                                <Button disableElevation variant={variant === 2 ? 'contained' : 'outlined'} onClick={() => handleVariant(2)}>Variant 2</Button>
                            </ButtonGroup>

                            <div className="email-form__preview__logo">
                                <img src={gmail} />
                            </div>

                            <div className="email-form__preview__subject">
                                {data['emailSubject' + variant]}
                            </div>
                            <div className="email-form__preview__body">

                                {data['email' + variant] && renderWithLineBreaks(data['email' + variant])}
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div className="form__footer">
                <div className="form__footer__left">
                    <Button disableElevation variant="outlined" onClick={previousStep}>Vorige</Button>
                </div>
                <div className="form__footer__center"></div>
                <div className="form__footer__right">
                    <Button disableElevation variant="contained" onClick={nextStep}>Volgende</Button>
                </div>
            </div>
        </div>
    )
}
export default EmailForm;