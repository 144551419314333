import { useState } from 'react';
import './index.scss';
import { useNavigate } from "react-router-dom";

function IntroductionView({ }) {

    const navigate = useNavigate();


    const handleClick = () => {
        navigate("/form");
    }

    return (
        <div className="introduction-view">
            <div className='introduction-view__full-width'>
                <div className='introduction-view__topbar'>
                    <div className='introduction-view__logo'></div>
                </div>
                <div className='introduction-view__main'>
                    <div className='introduction-view__left'>
                        <div className="introduction-view__topline">Eventcreator.nl</div>
                        <h1>Event organisatie met AI</h1>
                        <h3>Laat EventCreator je helpen en inspireren bij het organiseren van jouw evenement. Dit is een experiment uit de AI playground van <a href="https://www.livewall.nl" target="_blank" rel="noreferrer">LiveWall</a></h3>
                        <div className="button" onClick={handleClick}>Start</div>
                    </div>
                    <div className='introduction-view__right'>

                    </div>

                </div>
            </div>
        </div>

    )
}

export default IntroductionView;