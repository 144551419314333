import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import './index.scss';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Box, CircularProgress, FormControl } from '@mui/material';
import helper from '../../helpers/helper';
import MarkDown from 'react-markdown';

function EmailForm({ onChange, data, nextStep, previousStep }) {
    const [loading, setLoading] = useState(false);

    const handleGenerate = async () => {
        setLoading(true);
        let result = await axios.post(helper.getDomain() + "/api/events/request", { ...data, type: 'newsArticle' });
        let resultData = result.data?.data?.data;
        setLoading(false);
        onChange('newsArticle', resultData);
    }


    return (
        <div className="news-article-form">
            <div className="news-article-form__container">
                <div className="news-article-form__container__left">
                    <h2>Je nieuwsartikel</h2>
                    <h3>Genereer nu je nieuwsartikel</h3>
                    <p>
                        Met een druk op de knop kun je een nieuwsartikel of persbericht genereren. Met de aanvullende instructies kun je extra sturing geven om het artikel te genereren.
                    </p>
                    <div className="field__item">
                        <FormControl fullWidth sx={{ marginTop: 2, marginBottom: 2 }} >

                            <TextField
                                label="Aanvullende sturing aan je artikel"
                                multiline
                                fullWidth
                                minRows={2}
                                maxRows={5}
                                value={data.newsArticlePrompt}
                                onChange={(event) => onChange('newsArticlePrompt', event.target.value)}
                            />
                        </FormControl>
                    </div>
                    {!loading && (
                        <Box style={{ marginTop: 2, marginBottom: '2em' }}>
                            <Button disableElevation variant="contained" onClick={() => handleGenerate()}>Genereer artikel</Button>
                        </Box>)}
                    {loading && (<CircularProgress style={{ marginTop: 2 }} />)}

                </div>

                {data.newsArticle && (
                    <div className="news-article-form__container__right">
                        <div className="news-article-form__preview">
                            {data.newsArticle && (<MarkDown>{data.newsArticle}</MarkDown>)}
                        </div>
                    </div>)}
            </div>

            <div className="news-article-form__footer">
                <div className="news-article-form__footer__left">
                    <Button disableElevation variant="outlined" onClick={previousStep}>Vorige</Button>
                </div>
                <div className="news-article-form__footer__center"></div>
                <div className="news-article-form__footer__right">
                    <Button disableElevation variant="contained" onClick={nextStep}>Volgende</Button>
                </div>
            </div>
        </div>
    )
}
export default EmailForm;