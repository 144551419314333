import { useState } from 'react';
import axios from 'axios';
import './index.scss';

function MobileView({ }) {

    return (
        <div className="mobile-placeholder">
            <div className="logo"></div>
            Sorry! EventCreator.nl werkt alleen op desktop.
            Meer info, ga naar <a href="https://www.livewallevents.nl" target="_blank" rel="noreferrer">www.livewallevents.nl</a>
        </div>

    )
}
export default MobileView;
