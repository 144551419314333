import './index.scss';

function Sidebar({ onChange, step }) {
    const steps = [
        '',
        'Start',
        'Event details',
        'Event omschrijving',
        'Email text',
        'Draaiboek',
        'Nieuwsbericht',
        'Social promotie',
        'Flyer',
        'Presentatie slides',
        'Terugblik'
    ]
    return (
        <div className="steps">

            {steps.map((item, index) => (
                <>
                    {index > 0 && (
                        <div key={index} className="steps__item" onClick={() => onChange(index)}>
                            <div className="steps__item__container">
                                <div className={"steps__item__icon " + (step >= index ? 'steps__item__icon--done' : '')}>
                                    {step >= index ? '✓' : index}
                                </div>
                                <div className="steps__item__title">
                                    {item}
                                </div>
                            </div>
                            {index !== steps.length - 1 && <div className="steps__item__line-bottom"></div>}
                        </div>)}
                </>
            ))}
        </div >
    )
}
export default Sidebar;
