import './App.scss';
import IntroductionView from './components/IntroductionView';
import BaseView from './components/BaseView';
import MobileView from './components/MobileView';
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  if (window.screen.width < 700) {
    return <MobileView />
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<IntroductionView />} />
          <Route path="form" element={<BaseView />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
