import { useState } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { Alert, Button, CircularProgress } from '@mui/material';
import { DateField } from '@mui/x-date-pickers/DateField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import helper from '../../helpers/helper';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import './index.scss';


function DetailsForm({ onChange, data, nextStep, previousStep }) {
    const [loading, setLoading] = useState(false);
    const [activitySuggestions, setActivitySuggestions] = useState(false);
    const [error, setError] = useState(false);

    const generateActivities = async (retry = false) => {
        setLoading(true);

        let result = await axios.post(helper.getDomain() + "/api/events/request", { ...data, type: 'activitySuggestions' });
        let resultData = result.data?.data?.data;
        setLoading(false);
        let activitiesJSONString = extractBetweenBrackets(resultData);
        try {
            let activitiesList = JSON.parse(activitiesJSONString);
            setActivitySuggestions(activitiesList);
        }
        catch (e) {
            console.log("JSON error", e, activitiesJSONString);
            if (!retry) {
                generateActivities(true);
            }
        }
    }

    function extractBetweenBrackets(inputString) {
        const startIndex = inputString.indexOf('[');
        const lastIndex = inputString.lastIndexOf(']');

        if (startIndex !== -1 && lastIndex !== -1 && lastIndex > startIndex) {
            return inputString.substring(startIndex, lastIndex + 1);
        } else {
            return '';
        }
    }

    const nextClick = () => {
        if (!data.date) {
            window.scrollTo(0, 0)
            setError("Je dient een datum in te vullen");
            return;
        }
        if (!data.description_targetAudience) {
            window.scrollTo(0, 0)
            setError("Je dient een doelgroep in te vullen");
            return;
        }
        if (!data.description_program) {
            window.scrollTo(0, 0)
            setError("Je dient een programma in te vullen");
            return;
        }

        nextStep();
    }


    return (
        <div className="details-form">
            <div className="details-form__container">
                <h2>Beschrijving van je event</h2>
                <h3>Omschrijf uitgebreid hoe het event eruit ziet in je eigen woorden. </h3>

                {error && (
                    <Alert severity="error" sx={{ marginTop: 2 }}>{error}</Alert>
                )}

                <div className="field__item">
                    <FormControl sx={{ marginTop: 3 }} fullWidth >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateField
                                label="📅 Datum van het event"
                                value={typeof data.date == 'string' ? dayjs(data.date, "DD-MM-YYYY") : data.date}
                                format="DD-MM-YYYY"
                                onChange={(newValue) => onChange('date', dayjs(newValue).format('DD-MM-YYYY'))}
                            />
                        </LocalizationProvider>
                    </FormControl>
                </div>

                <div className="field__item">
                    <FormControl sx={{ marginTop: 3 }} fullWidth>
                        <TextField
                            id="outlined-multiline-flexible"
                            label="🌍 Locatie van het evenement"
                            fullWidth
                            value={data.location}
                            onChange={(event) => onChange('location', event.target.value)}
                        />
                    </FormControl>
                </div>

                <div className="field__item">
                    <FormControl sx={{ marginTop: 3 }} fullWidth>
                        <TextField
                            label="🧑‍🤝‍🧑 Doelgroep: wie komen er?"
                            multiline
                            fullWidth
                            minRows={2}
                            maxRows={10}
                            value={data.description_targetAudience}
                            onChange={(event) => onChange('description_targetAudience', event.target.value)}
                        />
                    </FormControl>
                </div>

                <div className="field__item">
                    <FormControl sx={{ marginTop: 3 }} fullWidth>
                        <TextField
                            label="🕦 Beschrijving van het programma: wat op welke tijd?"
                            multiline
                            fullWidth
                            minRows={2}
                            maxRows={10}
                            value={data.description_program}
                            onChange={(event) => onChange('description_program', event.target.value)}
                        />
                    </FormControl>
                </div>

                <div className="field__item">
                    <FormControl sx={{ marginTop: 3 }} fullWidth>
                        <TextField
                            label="🎉 Beschrijving van de activiteiten: wat ga je doen?"
                            multiline
                            fullWidth
                            minRows={2}
                            maxRows={10}
                            value={data.description_activities}
                            onChange={(event) => onChange('description_activities', event.target.value)}
                        />
                    </FormControl>
                    <div className="field__item__generate-name">
                        {loading && <CircularProgress style={{ marginTop: '0.5em' }} />}
                        {!loading && <Button variant="text" onClick={generateActivities}>Suggereer activiteiten</Button>}
                    </div>
                </div>


                <div className="field__item">
                    <FormControl sx={{ marginTop: 3 }} fullWidth>
                        <TextField
                            label="🍴 Details over de catering: wat gaan we eten?"
                            multiline
                            fullWidth
                            minRows={2}
                            maxRows={10}
                            value={data.description_catering}
                            onChange={(event) => onChange('description_catering', event.target.value)}
                        />
                    </FormControl>
                </div>

                <div className="field__item">
                    <FormControl sx={{ marginTop: 3 }} fullWidth>
                        <TextField
                            label="📖 Praktische informatie"
                            multiline
                            fullWidth
                            minRows={2}
                            maxRows={10}
                            value={data.description_practical}
                            onChange={(event) => onChange('description_practical', event.target.value)}
                        />
                    </FormControl>
                </div>

                <div className="field__item">
                    <FormControl sx={{ marginTop: 4, marginBottom: 4 }} fullWidth >
                        <TextField
                            label="Beschrijf de stijl waarin je wil communiceren"
                            multiline
                            fullWidth
                            minRows={2}
                            maxRows={5}
                            value={data.styleDescription}
                            onChange={(event) => onChange('styleDescription', event.target.value)}
                        />
                    </FormControl>
                </div>


            </div>
            <div className="form__footer">
                <div className="form__footer__left">
                    <Button disableElevation variant="outlined" onClick={previousStep}>Vorige</Button>
                </div>
                <div className="form__footer__center"></div>
                <div className="form__footer__right">
                    <Button disableElevation variant="contained" onClick={nextClick}>Volgende</Button>
                </div>
            </div>

            {activitySuggestions && (
                <Dialog
                    open={activitySuggestions}
                    onClose={() => setActivitySuggestions(false)}
                    fullWidth={true}
                    maxWidth={'lg'}
                >
                    <DialogTitle id="alert-dialog-title">
                        Suggesties voor activiteiten
                    </DialogTitle>
                    <DialogContent>
                        <Table>
                            <TableBody>
                                {activitySuggestions.map((row, index) => (
                                    <TableRow
                                        key={row.name}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.activity}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </DialogContent>

                </Dialog>
            )}
        </div>
    )
}
export default DetailsForm;