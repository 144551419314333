import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import './index.scss';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Box, CircularProgress, FormControl } from '@mui/material';
import placeholder from './placeholder.jpg';
import helper from '../../helpers/helper';

const started = 0;
function SocialForm({ onChange, data, nextStep, previousStep }) {
    const [loading, setLoading] = useState(false);


    function extractBetweenBrackets(inputString) {
        const startIndex = inputString.indexOf('[');
        const lastIndex = inputString.lastIndexOf(']');

        if (startIndex !== -1 && lastIndex !== -1 && lastIndex > startIndex) {
            return inputString.substring(startIndex, lastIndex + 1);
        } else {
            return '';
        }
    }

    const handleGenerate = async (retry = false) => {
        setLoading(true);

        // Get social posts
        let result = await axios.post(helper.getDomain() + "/api/events/request", { ...data, type: 'social' });
        let resultData = result.data?.data?.data;

        setLoading(false);
        let posts = extractBetweenBrackets(resultData);
        try {
            let postsResult = JSON.parse(posts);
            onChange('socialPosts', postsResult);
            setTimeout(handleGenerateImages,500);

        }
        catch (e) {
            console.log("JSON error", e, posts);
            if (!retry) {
                handleGenerate(true);
            }
        }

    }

    const handleGenerateImages = async (retry = false) => {
        setLoading(true);

        // Get social posts
        let result = await axios.post(helper.getDomain() + "/api/events/images", { ...data });
        let images = result.data?.data?.data;
        setLoading(false);
        try {
            onChange('socialPostsImages', images);
        }
        catch (e) {

        }
    }

    const renderWithLineBreaks = (text) => {
        const lines = text.split('\n');
        return lines.map((line, index) => (
            <React.Fragment key={index}>
                {line}
                {index !== lines.length - 1 && <br />} {/* Add <br> except after the last line */}
            </React.Fragment>
        ));
    };


    return (
        <div className="social-form" >
            <div className="social-form__container">
                <div className="social-form__container__left">
                    <h2>Je social promotie</h2>
                    <h3>Genereer nu je social media promotie posts</h3>
                    <div className="field__item">
                        <FormControl fullWidth sx={{ marginTop: 2, marginBottom: 2 }} >
                            <TextField
                                label="Aanvullende sturing aan je posts"
                                multiline
                                fullWidth
                                minRows={2}
                                maxRows={5}
                                value={data.socialPrompt}
                                onChange={(event) => onChange('socialPrompt', event.target.value)}
                            />
                        </FormControl>
                    </div>

                    {!loading && (
                        <Box style={{ marginTop: 2, marginBottom: '2em' }}>
                            <Button disableElevation variant="contained" onClick={() => handleGenerate()}>Genereer socials</Button>
                        </Box>)}
                    {loading && (<CircularProgress style={{ marginTop: 2 }} />)}

                </div>

                <div className="social-form__container__right">
                    {data.socialPosts && data.socialPosts.map((post, index) => (

                        <div className="social-form__preview" key={index}>
                            <div className="social-form__preview__header">
                                <div className="social-form__preview__icon">{data.name.substring(0, 1)}</div>
                                <div className="social-form__preview__header__right">
                                    <div className="social-form__preview__name">{data.name}</div>
                                    <div className="social-form__preview__role">Event Manager</div>

                                </div>
                            </div>
                            <div className="social-form__preview__text">{renderWithLineBreaks(post.post)}</div>
                            <div className="social-form__preview__image"><img src={data.socialPostsImages && data.socialPostsImages[index] ? data.socialPostsImages[index].url : placeholder} /></div>


                        </div>

                    ))}
                </div>
            </div>

            <div className="form__footer">
                <div className="form__footer__left">
                    <Button disableElevation variant="outlined" onClick={previousStep}>Vorige</Button>
                </div>
                <div className="form__footer__center"></div>
                <div className="form__footer__right">
                    <Button disableElevation variant="contained" onClick={nextStep}>Volgende</Button>
                </div>
            </div>
        </div>
    )
}
export default SocialForm;