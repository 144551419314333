import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import './index.scss';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Box, CircularProgress, FormControl } from '@mui/material';
import helper from '../../helpers/helper';

function LookbackForm({ onChange, data, nextStep, previousStep }) {
    const [loading, setLoading] = useState(false);

    const handleGenerate = async () => {
        setLoading(true);
        let result = await axios.post(helper.getDomain() + "/api/events/request", { ...data, type: 'lookback' });
        let resultData = result.data?.data?.data;
        setLoading(false);
        onChange('lookbackArticle', resultData);
    }

    const renderWithLineBreaks = (text) => {
        const lines = text.split('\n');
        return lines.map((line, index) => (
            <React.Fragment key={index}>
                {index === 0 ? <b>{line}</b> : line}
                {index !== lines.length - 1 && <br />} {/* Add <br> except after the last line */}
            </React.Fragment>
        ));
    };

    return (
        <div className="lookback-form">
            <div className="lookback-form__container">
                <div className="form__container__left">
                    <h1>Je terugblik</h1>
                    <h3>Genereer een artikel met een terugblik</h3>
                    <p>
                        Je event is nu voorbij. Altijd leuk om een nieuwsbericht te schrijven na afloop.
                        Laat weten wat de hoogtepunten van de dag waren en wat er allemaal is gebeurd. We maken direct een artikel voor je.
                    </p>
                    <div className="field__item">
                        <FormControl fullWidth sx={{ marginTop: 2, marginBottom: 2 }} >

                            <TextField
                                label="Highlights"
                                multiline
                                fullWidth
                                minRows={5}
                                maxRows={10}
                                value={data.lookbackHighlights}
                                onChange={(event) => onChange('lookbackHighlights', event.target.value)}
                            />
                        </FormControl>
                    </div>
                    {!loading && (
                        <Box style={{ marginTop: 2, marginBottom: '2em' }}>
                            <Button disableElevation variant="contained" onClick={() => handleGenerate()}>Genereer artikel</Button>
                        </Box>)}
                    {loading && (<CircularProgress style={{ marginTop: 2 }} />)}

                </div>

                <div className="form__container__right">
                    {data.lookbackArticle && (
                        <div className="lookback-form__preview">
                            {data.lookbackArticle && renderWithLineBreaks(data.lookbackArticle)}
                        </div>
                    )}
                </div>
            </div>

            <div className="form__footer">
                <div className="form__footer__left">
                    <Button disableElevation variant="outlined" onClick={previousStep}>Vorige</Button>
                </div>
                <div className="form__footer__center"></div>
                <div className="form__footer__right">
                    <Button disableElevation variant="contained" onClick={nextStep}>Volgende</Button>
                </div>
            </div>
        </div>
    )
}
export default LookbackForm;