import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import './index.scss';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Box, CircularProgress, FormControl } from '@mui/material';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import helper from '../../helpers/helper';

function ScriptForm({ onChange, data, nextStep, previousStep }) {
    const [loading, setLoading] = useState(false);


    const handleGenerate = async () => {
        setLoading(true);
        let result = await axios.post(helper.getDomain() + "/api/events/request", { ...data, type: 'script' });
        let resultData = result.data?.data?.data;
        setLoading(false);
        onChange('script', resultData);
    }

    return (
        <div className="script-form">
            <div className="form__container">
                <div className="form__container__left">
                    <h2>Draaiboek</h2>
                    <h3>Genereer het draaiboek</h3>
                    <p>
                        Genereer met een druk op de knop het draaiboek voor je evenement. Met de aanvullende instructies kun je extra sturing geven aan de generatie.
                    </p>
                    <div className="field__item">
                        <FormControl fullWidth sx={{ marginTop: 2, marginBottom: 2 }} >

                            <TextField
                                label="Aanvullende sturing aan het draaiboek"
                                multiline
                                fullWidth
                                minRows={2}
                                maxRows={5}
                                value={data.scriptPrompt}
                                onChange={(event) => onChange('scriptPrompt', event.target.value)}
                            />
                        </FormControl>
                    </div>

                    {!loading && (
                        <Box style={{ marginTop: 2, marginBottom: '2em' }}>
                            <Button disableElevation variant="contained" onClick={() => handleGenerate()}>Genereer draaiboek</Button>
                        </Box>)}
                    {loading && (<CircularProgress style={{ marginTop: 2 }} />)}

                </div>

                {data.script && (
                    <div className="form__container__right">
                        <div className="script-form__preview">
                            {data.script && <ReactMarkdown remarkPlugins={[remarkGfm]}>{data.script}</ReactMarkdown>}
                        </div>
                    </div>
                )}
            </div>

            <div className="form__footer">
                <div className="form__footer__left">
                    <Button disableElevation variant="outlined" onClick={previousStep}>Vorige</Button>
                </div>
                <div className="form__footer__center"></div>
                <div className="form__footer__right">
                    <Button disableElevation variant="contained" onClick={nextStep}>Volgende</Button>
                </div>
            </div>
        </div >
    )
}
export default ScriptForm;